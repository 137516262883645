const state = {
  plan_calendar_id: 0,
  plan_calendar_detail_data: [],
  plan_calendar_detail_pending_data: [],
  plan_calendar_detail_reject_data: [],
  plan_calendar_detail_success_data: [],
  plan_calendar_detail_cancel_data: [],
  plan_calendar_detail_wait_confirm_data: [],
  plan_calendar_detail_pagination: {},
  plan_calendar_detail_pending_pagination: {},
  plan_calendar_detail_reject_pagination: {},
  plan_calendar_detail_success_pagination: {},
  plan_calendar_detail_cancel_pagination: {},
  plan_calendar_detail_wait_confirm_pagination: {},
};

const mutations = {
  ADD_PLAN_CALENDAR_DETAIL_DATA(state, data) {
    state.plan_calendar_detail_data = data;
  },
  ADD_PLAN_CALENDAR_DETAIL_PENDING_DATA(state, data) {
    state.plan_calendar_detail_pending_data = data;
  },
  ADD_PLAN_CALENDAR_DETAIL_REJECT_DATA(state, data) {
    state.plan_calendar_detail_reject_data = data;
  },
  ADD_PLAN_CALENDAR_DETAIL_SUCCESS_DATA(state, data) {
    state.plan_calendar_detail_success_data = data;
  },
  ADD_PLAN_CALENDAR_DETAIL_CANCEL_DATA(state, data) {
    state.plan_calendar_detail_cancel_data = data;
  },
  ADD_PLAN_CALENDAR_DETAIL_WAIT_CONFIRM_DATA(state, data) {
    state.plan_calendar_detail_wait_confirm_data = data;
  },
  ADD_PLAN_CALENDAR_DETAIL_PAGINATION(state, data) {
    state.plan_calendar_detail_pagination = data;
  },
  ADD_PLAN_CALENDAR_DETAIL_PENDING_PAGINATION(state, data) {
    state.plan_calendar_detail_pending_pagination = data;
  },
  ADD_PLAN_CALENDAR_DETAIL_REJECT_PAGINATION(state, data) {
    state.plan_calendar_detail_reject_pagination = data;
  },
  ADD_PLAN_CALENDAR_DETAIL_SUCCESS_PAGINATION(state, data) {
    state.plan_calendar_detail_success_pagination = data;
  },
  ADD_PLAN_CALENDAR_DETAIL_CANCEL_PAGINATION(state, data) {
    state.plan_calendar_detail_cancel_pagination = data;
  },
  ADD_PLAN_CALENDAR_DETAIL_WAIT_CONFIRM_PAGINATION(state, data) {
    state.plan_calendar_detail_wait_confirm_pagination = data;
  },
  ADD_PLAN_CALENDAR_ID(state, data) {
    state.plan_calendar_id = data;
  },
};

const actions = {
  addPlanCalendarDetailData({ commit }, data) {
    commit('ADD_PLAN_CALENDAR_DETAIL_DATA', data);
  },
  addPlanCalendarDetailPendingData({ commit }, data) {
    commit('ADD_PLAN_CALENDAR_DETAIL_PENDING_DATA', data);
  },
  addPlanCalendarDetailRejectData({ commit }, data) {
    commit('ADD_PLAN_CALENDAR_DETAIL_REJECT_DATA', data);
  },
  addPlanCalendarDetailSuccessData({ commit }, data) {
    commit('ADD_PLAN_CALENDAR_DETAIL_SUCCESS_DATA', data);
  },
  addPlanCalendarDetailCancelData({ commit }, data) {
    commit('ADD_PLAN_CALENDAR_DETAIL_CANCEL_DATA', data);
  },
  addPlanCalendarDetailWaitConfirmData({ commit }, data) {
    commit('ADD_PLAN_CALENDAR_DETAIL_WAIT_CONFIRM_DATA', data);
  },
  addPlanCalendarDetailPagination({ commit }, data) {
    commit('ADD_PLAN_CALENDAR_DETAIL_PAGINATION', data);
  },
  addPlanCalendarDetailPendingPagination({ commit }, data) {
    commit('ADD_PLAN_CALENDAR_DETAIL_PENDING_PAGINATION', data);
  },
  addPlanCalendarDetailRejectPagination({ commit }, data) {
    commit('ADD_PLAN_CALENDAR_DETAIL_REJECT_PAGINATION', data);
  },
  addPlanCalendarDetailSuccessPagination({ commit }, data) {
    commit('ADD_PLAN_CALENDAR_DETAIL_SUCCESS_PAGINATION', data);
  },
  addPlanCalendarDetailCancelPagination({ commit }, data) {
    commit('ADD_PLAN_CALENDAR_DETAIL_CANCEL_PAGINATION', data);
  },
  addPlanCalendarDetailWaitConfirmPagination({ commit }, data) {
    commit('ADD_PLAN_CALENDAR_DETAIL_WAIT_CONFIRM_PAGINATION', data);
  },
  addPlanCalendarId({ commit }, data) {
    commit('ADD_PLAN_CALENDAR_ID', data);
  },
};

const getters = {
  allPlanCalendarDetailData: (state) => state.plan_calendar_detail_data,
  allPlanCalendarDetailPendingData: (state) => state.plan_calendar_detail_pending_data,
  allPlanCalendarDetailRejectData: (state) => state.plan_calendar_detail_reject_data,
  allPlanCalendarDetailSuccessData: (state) => state.plan_calendar_detail_success_data,
  allPlanCalendarDetailCancelData: (state) => state.plan_calendar_detail_cancel_data,
  allPlanCalendarDetailWaitConfirmData: (state) => state.plan_calendar_detail_wait_confirm_data,
  allPlanCalendarDetailPagination: (state) => state.plan_calendar_detail_pagination,
  allPlanCalendarDetailPendingPagination: (state) => state.plan_calendar_detail_pending_pagination,
  allPlanCalendarDetailRejectPagination: (state) => state.plan_calendar_detail_reject_pagination,
  allPlanCalendarDetailSuccessPagination: (state) => state.plan_calendar_detail_success_pagination,
  allPlanCalendarDetailCancelPagination: (state) => state.plan_calendar_detail_cancel_pagination,
  allPlanCalendarDetailWaitConfirmPagination: (state) => state.plan_calendar_detail_wait_confirm_pagination,
  planCalendarId: (state) => state.plan_calendar_id,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
